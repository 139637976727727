@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinner for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.conf-highlight > td {
  background-color: #ecf2e2 !important;
  color: '#556B2F' !important;
}

.text-muted {
  color: #6c757d !important;
}

.conf-highlight.text-muted > td {
  background-color: #f8f9fa !important;
}
